
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function singleVehicleGarageRT () {
    return _createElement('div', { 'className': 'cm_garage' }, _createElement('a', { 'className': 'garage-button d-flex justify-content-between text-secondary text-decoration-none bg-transparent' }, _createElement('div', {
        'className': 'd-flex',
        'aria-label': 'button'
    }, [_createElement('svg', {
            'xmlns': 'http://www.w3.org/2000/svg',
            'width': '32',
            'height': '32',
            'className': 'icon-garage',
            'id': 'icon-garage',
            'viewBox': '0 0 24 24',
            'fill': 'currentColor',
            'stroke': 'currentColor',
            'strokeWidth': '1',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round',
            'key': '1850'
        }, _createElement('g', { 'transform': 'matrix(0.055058, 0, 0, 0.055557, 0, 0)' }, _createElement('g', {}, _createElement('path', { 'd': 'M430.902,120.789l-208.1-115.6c-3-1.7-6.7-1.7-9.7,0l-208,115.6c-3.2,1.8-5.1,5.1-5.1,8.7v292.7c-0.1,5.4,4.2,9.8,9.6,9.8 c0.1,0,0.2,0,0.2,0h416.3c5.4,0.1,9.8-4.2,9.8-9.6c0-0.1,0-0.2,0-0.2v-292.6C436.002,125.889,434.102,122.589,430.902,120.789z M358.002,412.089h-280v-41h280V412.089z M358.002,351.089h-280v-38h280V351.089z M358.002,293.089h-280v-38h280V293.089z M358.002,235.089h-280v-38h280V235.089z M416.002,412.089h-38v-224.7c0-5.5-4.1-10.3-9.7-10.3h-300.6c-5.5,0-9.7,4.8-9.7,10.3 v224.7h-38v-276.7l198-110.1l198,110.1V412.089z' }))))], this.template === 'active' ? _createElement('div', {
        'className': 'cmTemplate_active',
        'key': '1074'
    }, [this.changeButton(function () {
            return _createElement('div', { 'className': 'ml-3 cm_changeButton' }, window.themeSettings.locales.select_vehicle);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]) : null, this.template === 'locked' ? _createElement('div', {
        'data-cm-role': 'go-to-results',
        'className': 'ml-3 cmTemplate_locked',
        'key': '1440'
    }, this.vehicleString) : null), this.template === 'locked' ? _createElement('div', {
        'className': 'cmTemplate_locked',
        'key': '1592'
    }, _createElement('div', { 'className': 'd-flex' }, [this.changeButton(function () {
            return _createElement('div', {
                'className': 'd-flex cm_changeButton',
                'aria-label': 'button'
            }, _createElement('div', { 'className': 'text-primary mx-3 single-vehicle-go-button' }, '\n            ', window.themeSettings.locales.change_vehicle, '\n          '));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', {
        'data-cm-role': 'discard-vehicle',
        'className': 'clear-garage text-primary align-self-auto'
    }, _createElement('span', { 'className': 'remove-model display-block' }, [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 22 22',
            'key': '23410'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])))) : null));
}
        export const componentNames = ["cm:changeButton","cm:changeButton"]