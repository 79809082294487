
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function garageRT () {
    return _createElement('div', { 'className': 'cm_garage' }, _createElement('a', {
        'className': 'cm_toggle-dropdown garage-button bg-transparent d-lg-block d-flex text-decoration-none text-secondary',
        'title': 'Garage: ' + this.size + ' item(s)'
    }, [_createElement('svg', {
            'xmlns': 'http://www.w3.org/2000/svg',
            'width': '32',
            'height': '32',
            'className': 'icon-garage',
            'id': 'icon-garage',
            'viewBox': '0 0 24 24',
            'fill': 'currentColor',
            'stroke': 'currentColor',
            'strokeWidth': '1',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round',
            'key': '1760'
        }, _createElement('g', { 'transform': 'matrix(0.055058, 0, 0, 0.055557, 0, 0)' }, _createElement('g', {}, _createElement('path', { 'd': 'M430.902,120.789l-208.1-115.6c-3-1.7-6.7-1.7-9.7,0l-208,115.6c-3.2,1.8-5.1,5.1-5.1,8.7v292.7c-0.1,5.4,4.2,9.8,9.6,9.8 c0.1,0,0.2,0,0.2,0h416.3c5.4,0.1,9.8-4.2,9.8-9.6c0-0.1,0-0.2,0-0.2v-292.6C436.002,125.889,434.102,122.589,430.902,120.789z M358.002,412.089h-280v-41h280V412.089z M358.002,351.089h-280v-38h280V351.089z M358.002,293.089h-280v-38h280V293.089z M358.002,235.089h-280v-38h280V235.089z M416.002,412.089h-38v-224.7c0-5.5-4.1-10.3-9.7-10.3h-300.6c-5.5,0-9.7,4.8-9.7,10.3 v224.7h-38v-276.7l198-110.1l198,110.1V412.089z' }))))], this.size ? [_createElement('div', {
            'className': 'header-badge d-flex position-absolute',
            'key': '10591'
        }, _createElement('span', { 'className': 'bg-primary text-white text-center font-weight-bold w-100' }, this.size))] : null), [this.garageDropdown(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', { 'className': 'garage-item align-items-center justify-content-between' }, _createElement('span', {
                            'className': 'model-name text-secondary',
                            'data-cm-role': 'select-vehicle'
                        }, this.vehicleString), _createElement('div', {
                            'className': 'show-vehicle-button text-primary mx-3',
                            'data-cm-role': 'select-vehicle'
                        }, '\n          ', window.themeSettings.locales.show_products, '\n        '), _createElement('span', {
                            'className': 'remove-model',
                            'data-cm-role': 'remove-vehicle'
                        }, [_createElement('svg', {
                                'className': 'cm_icon cm_icon-times',
                                'height': '20px',
                                'role': 'image',
                                'viewBox': '0 0 22 22',
                                'key': '4180'
                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_garageDropdown' }, this.template === 'list' ? _createElement('div', {
                'className': 'cmTemplate_list',
                'key': '31'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement('div', { 'className': 'garage-dropdown-header d-flex align-items-center justify-content-between mb-3' }, _createElement('div', { 'className': 'title-dropdown-garage text-secondary' }, window.themeSettings.locales.my_vehicles), _createElement('div', {
                'className': 'clear-garage text-primary align-self-center',
                'data-cm-role': 'clear-garage'
            }, '\n        ', window.themeSettings.locales.clear_garage, '\n      ')), _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.addButton(function () {
                    return _createElement('div', { 'className': 'align-self-start mt-3 p-2 btn btn-primary cm_addButton' }, [window.themeSettings.locales.add_vehicle]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])) : null, this.template === 'empty' ? _createElement('div', {
                'className': 'cmTemplate_empty',
                'key': '2015'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement('div', { 'className': 'garage-dropdown-header d-flex align-items-center justify-content-between mb-3' }, _createElement('div', { 'className': 'title-dropdown-garage text-secondary' }, window.themeSettings.locales.my_vehicles)), _createElement('ul', {}, _createElement('li', { 'className': 'vehicle-advantages' }, window.themeSettings.locales.garage_first_line), _createElement('li', { 'className': 'vehicle-advantages' }, window.themeSettings.locales.garage_second_line), _createElement('li', { 'className': 'vehicle-advantages' }, window.themeSettings.locales.garage_third_line)), [this.addButton(function () {
                    return _createElement('div', { 'className': 'align-self-start mt-3 p-2 btn btn-primary cm_addButton' }, [window.themeSettings.locales.add_vehicle]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:addButton","cm:addButton","cm:garageDropdown"]